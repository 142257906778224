import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Actions.module.sass";
import Icon from "../../../components/Icon";
import MarginTradingTransfer from "../../../components/MarginTradingTransfer";
import Form from "./Form";
import { getDigitsAfterDecimal } from "../../../components/helper";
import Modal from "../../../components/Modal";
import { useSelector } from "react-redux";
import LeverageTrading from "./LeverageTrading";

const navigation = ["Limit", "Market"];

const Actions = ({
  currentMarketDetails,
  exchangeData,
  orderResponse,
  currentMarketPrice,
  tradingType,
  setVisibleTransfer,
  visibleTransfer,
  slug,
  orderActionResponse,
  setOrderActionResponse,
  activeTab,
  setActiveTab,
  createOrdersData,
  leverage,
  setLeverage,
  leverageState,
  crossMarginLeverage,
  setCrossMarginLeverage,
  isolatedMarginLeverage,
  setIsolatedMarginLeverage,
  setMarketOrderType,
  marketOrderType,
  crossMarginSellLeverage,
  setCrossMarginSellLeverage,
  isolatedMarginSellLeverage,
  setIsolatedMarginSellLeverage,
  dataMarkets,
  socketMarketData
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleAction, setVisibleAction] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currencyAmount, setCurrencyAmount] = useState(0);
  const [coinAmount, setCoinAmount] = useState(0);
  const { userStatus } = useSelector((state) => { return state.getUserStatus });
  const [orderFlag, setOrderFlag] = useState(false);
  const [leverageModal, setLeverageModal] = useState(false);
  // const [marketLeverage, setMarketLeverage] = useState([]);

  useEffect(() => {
    if (tradingType?.toLowerCase() === "cross") {
      if (activeTab?.toLowerCase() === "cross") {
        userStatus?.cross_margin_wallet?.balances?.map((x) => {
          if (parseInt(x.is_usdt_market) === parseInt(process.env.REACT_APP_IS_USDT_MARKET_NOT_AVAILABLE) || parseInt(x.is_usdt_market) === parseInt(process.env.REACT_APP_IS_USDT_MARKET_APPLICABLE)) {
            setCurrencyAmount(x?.balance);
          }
          else {
            if (slug?.split("-")[0]?.toLowerCase() === x.symbol?.toLowerCase()) {
              setCoinAmount(x?.balance);
            }
          }
        });
      }
      else {
        userStatus?.isolated_margin_wallet?.balances?.map((x) => {
          if (parseInt(x.is_usdt_market) === parseInt(process.env.REACT_APP_IS_USDT_MARKET_NOT_AVAILABLE) || parseInt(x.is_usdt_market) === parseInt(process.env.REACT_APP_IS_USDT_MARKET_APPLICABLE)) {
            setCurrencyAmount(x?.balance);
          }
          else {
            if (slug?.split("-")[0]?.toLowerCase() === x.symbol?.toLowerCase()) {
              setCoinAmount(x?.balance);
            }
          }
        });
      }
    }
    else {
      userStatus?.wallet?.balances?.map((x) => {
        if (parseInt(x.is_usdt_market) === parseInt(process.env.REACT_APP_IS_USDT_MARKET_NOT_AVAILABLE) || parseInt(x.is_usdt_market) === parseInt(process.env.REACT_APP_IS_USDT_MARKET_APPLICABLE)) {
          setCurrencyAmount(x?.balance);
        }
        else {
          if (slug?.split("-")[0]?.toLowerCase() === x.symbol?.toLowerCase()) {
            setCoinAmount(x?.balance);
          }
        }
      });
    }
  }, [activeTab, orderFlag]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleClickBuy = () => {
    setVisibleAction(true);
    setVisible(true);
  };

  const handleClickSell = () => {
    setVisibleAction(false);
    setVisible(true);
  };

  const showCrossButtonText = () => {
    if (marketOrderType?.toLowerCase() === "buy" && activeTab?.toLowerCase() === "cross") {
      if (crossMarginLeverage === undefined || crossMarginLeverage === "") {
        if (leverageState?.currencyBalanceCrossMaxLeverage !== null) {
          return `${leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1]}x`
        }
        else {
          return <span>0x</span>
        }
      }
      else {
        return <span>{crossMarginLeverage}x</span>
      }
    }
    else {
      if (crossMarginSellLeverage === undefined || crossMarginSellLeverage === "") {
        if (leverageState?.coinBalanceCrossMaxLeverage !== null) {
          return `${leverageState?.coinBalanceCrossMaxLeverage[leverageState?.coinBalanceCrossMaxLeverage?.length - 1]}x`
        }
        else {
          return <span>0x</span>
        }
      }
      else {
        return <span>{crossMarginSellLeverage}x</span>
      }
    }
  };

  const showDropDownText = () => {
    if (activeTab?.toLowerCase() === "cross") {
      if (marketOrderType?.toLowerCase() === "buy") {
        if (crossMarginLeverage === undefined || crossMarginLeverage === "") {
          if (leverageState?.currencyBalanceCrossMaxLeverage !== null) {
            return `${leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1]}x`
          }
        }
        else {
          return <span>{crossMarginLeverage}x</span>
        }
      }
      else {
        if (crossMarginSellLeverage === undefined || crossMarginSellLeverage === "") {
          if (leverageState?.coinBalanceCrossMaxLeverage !== null) {
            return `${leverageState?.coinBalanceCrossMaxLeverage[leverageState?.coinBalanceCrossMaxLeverage?.length - 1]}x`

          }
        }
        else {
          return <span>{crossMarginSellLeverage}x</span>
        }
      }
    }
    else {
      if (marketOrderType?.toLowerCase() === "buy") {
        if (isolatedMarginLeverage === undefined || isolatedMarginLeverage === "") {
          if (leverageState?.currencyBalanceIsolatedMaxLeverage !== null) {
            return `${leverageState?.currencyBalanceIsolatedMaxLeverage[leverageState?.currencyBalanceIsolatedMaxLeverage?.length - 1]}x`
          }
        }
        else {
          return <span>{isolatedMarginLeverage}x</span>
        }
      }
      else {
        if (isolatedMarginSellLeverage === undefined || isolatedMarginSellLeverage === "") {
          if (leverageState?.coinBalanceIsolatedMaxLeverage !== null) {
            return `${leverageState?.coinBalanceIsolatedMaxLeverage[leverageState?.coinBalanceIsolatedMaxLeverage?.length - 1]}x`
          }
        }
        else {
          return <span>{isolatedMarginSellLeverage}x</span>
        }
      }
    }
  };

  return (
    <>
      <div className={styles.actions}>
        {tradingType === "cross" &&
          <div className={styles.container}>
            <div className={styles.tabs}>
              {/* <div
              className={cn(styles.tab, { [styles.active]: activeTab === 'spot' })}
              onClick={() => handleTabClick('spot')}
            >
              Spot
            </div> */}
              <div
                className={cn(styles.tab, { [styles.active]: activeTab === 'cross' })}
                onClick={() => handleTabClick('cross')}
              >
                {/* Cross {currentMarketDetails[0]?.isMarginTrading === parseInt(process.env.REACT_APP_MARGIN_TRADING_ENABLE) && <span>{currentMarketDetails[0] != null && currentMarketDetails[0]?.maxLeverageTrading[currentMarketDetails[0]?.maxLeverageTrading?.length - 1]}x</span>} */}
                {/* Cross {leverage === undefined || leverage === "" ?
                  currentMarketDetails[0]?.maxLeverageTrading !== null ? <span>{currentMarketDetails[0]?.maxLeverageTrading[currentMarketDetails[0]?.maxLeverageTrading?.length - 1]}x</span> : "0x"
                  :
                  <span>{leverage}x</span>} */}
                Cross {
                  showCrossButtonText()
                }
              </div>
              <div
                className={cn(styles.tab, { [styles.active]: activeTab === 'isolated' })}
                onClick={() => handleTabClick('isolated')}
              >
                Isolated
              </div>
            </div>
            <div className={styles.maxLeverage}>
              <div className={cn(styles.maxLeverageTab)}
                disabled={currentMarketDetails[0]?.maxLeverageTrading !== null}
                onClick={() => {
                  if (leverageState !== null) {
                    setLeverageModal(true);
                  }
                }}>
                {
                  // leverage == "" ?
                  //   (leverageState !== null && leverageState?.length > 0) ?
                  //     activeTab?.toLowerCase() === "cross" ?
                  //       ""
                  //       :
                  //       leverageState?.currencyBalanceCrossMaxLeverage[leverageState?.currencyBalanceCrossMaxLeverage?.length - 1]
                  //     : "0"
                  //   :
                  //   leverage
                  showDropDownText()
                }
                <Icon name="arrow-down" size="20" />
              </div>
              <div className={cn(styles.tab)} onClick={() => setVisibleTransfer(true)}>
                Transfer
              </div>
            </div>
          </div>
        }
        <div className={styles.head}>
          <div className={styles.nav}>
            {navigation.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          <div className={styles.info}>
            Crypto trading tutorial
            <Link to="/learn-crypto">
              Learn now <Icon name="arrow-right" size="20" />
            </Link>
          </div>
        </div>
        <div className={cn(styles.wrapper, { [styles.show]: visible })}>
          {activeIndex === 0 && (
            <Form
              contentBuy={getDigitsAfterDecimal(exchangeData?.data?.data?.currencyBalance, currentMarketDetails[0]?.priceDecimalPrecision)}
              contentSell={getDigitsAfterDecimal(exchangeData?.data?.data?.coinBalance, currentMarketDetails[0]?.amountDecimalPrecision)}
              limit
              visible={visibleAction}
              setValue={setVisible}
              currentPrice={(currentMarketPrice?.currentMarketPrice === undefined || currentMarketPrice?.currentMarketPrice === null) ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : currentMarketPrice?.currentMarketPrice}
              slug={currentMarketDetails[0]?.slug}
              marketId={currentMarketDetails[0]?.market_id}
              currency={currentMarketDetails[0]?.currency}
              coin={currentMarketDetails[0]?.coin}
              orderResponse={orderResponse}
              tradingType={tradingType}
              currentMarketDetails={currentMarketDetails}
              activeTab={activeTab}
              currencyAmount={currencyAmount}
              coinAmount={coinAmount}
              setOrderFlag={setOrderFlag}
              orderFlag={orderFlag}
              exchangeData={exchangeData}
              createOrdersData={createOrdersData}
              activeIndexAction={activeIndex}
              leverage={leverage}
              currentMarketPrice={getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision)}
              setLeverage={setLeverage}
              leverageState={leverageState}
              crossMarginLeverage={crossMarginLeverage}
              setCrossMarginLeverage={setCrossMarginLeverage}
              isolatedMarginLeverage={isolatedMarginLeverage}
              setIsolatedMarginLeverage={setIsolatedMarginLeverage}
              setMarketOrderType={setMarketOrderType}
              marketOrderType={marketOrderType}
              crossMarginSellLeverage={crossMarginSellLeverage}
              isolatedMarginSellLeverage={isolatedMarginSellLeverage}
            />
          )}

          {activeIndex === 1 && (
            <Form
              contentBuy={getDigitsAfterDecimal(exchangeData?.data?.data?.currencyBalance, currentMarketDetails[0]?.priceDecimalPrecision)}
              contentSell={getDigitsAfterDecimal(exchangeData?.data?.data?.coinBalance, currentMarketDetails[0]?.amountDecimalPrecision)}
              price
              visible={visibleAction}
              setValue={setVisible}
              currentPrice={(currentMarketPrice?.currentMarketPrice === undefined || currentMarketPrice?.currentMarketPrice === null) ? getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision) : currentMarketPrice?.currentMarketPrice}
              slug={currentMarketDetails[0]?.slug}
              marketId={currentMarketDetails[0]?.market_id}
              currency={currentMarketDetails[0]?.currency}
              coin={currentMarketDetails[0]?.coin}
              orderResponse={orderResponse}
              tradingType={tradingType}
              currentMarketDetails={currentMarketDetails}
              activeTab={activeTab}
              currencyAmount={currencyAmount}
              coinAmount={coinAmount}
              setOrderFlag={setOrderFlag}
              orderFlag={orderFlag}
              exchangeData={exchangeData}
              createOrdersData={createOrdersData}
              activeIndexAction={activeIndex}
              leverage={leverage}
              currentMarketPrice={getDigitsAfterDecimal(currentMarketDetails[0]?.currentMarketPrice, currentMarketDetails[0]?.priceDecimalPrecision)}
              setLeverage={setLeverage}
              leverageState={leverageState}
              crossMarginLeverage={crossMarginLeverage}
              setCrossMarginLeverage={setCrossMarginLeverage}
              isolatedMarginLeverage={isolatedMarginLeverage}
              setIsolatedMarginLeverage={setIsolatedMarginLeverage}
              setMarketOrderType={setMarketOrderType}
              marketOrderType={marketOrderType}
              crossMarginSellLeverage={crossMarginSellLeverage}
              isolatedMarginSellLeverage={isolatedMarginSellLeverage}
            />
          )}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-green button-small", styles.button)}
            onClick={() => handleClickBuy()}
          >
            Buy
          </button>
          <button
            className={cn("button-red button-small", styles.button)}
            onClick={() => handleClickSell()}
          >
            Sell
          </button>
        </div>
      </div>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <MarginTradingTransfer
          setVisibleModal={setVisibleTransfer}
          orderActionResponse={orderActionResponse}
          setOrderActionResponse={setOrderActionResponse}
          dataMarkets={dataMarkets}
          socketMarketData={socketMarketData}
        />
      </Modal>
      <Modal
        visible={leverageModal}
        onClose={() => setLeverageModal(false)}
      >
        <LeverageTrading
          setVisibleModal={setLeverageModal}
          leverageState={leverageState}
          activeTab={activeTab}
          setCrossMarginLeverage={setCrossMarginLeverage}
          setIsolatedMarginLeverage={setIsolatedMarginLeverage}
          marketOrderType={marketOrderType}
          setCrossMarginSellLeverage={setCrossMarginSellLeverage}
          setIsolatedMarginSellLeverage={setIsolatedMarginSellLeverage}
        />
      </Modal>
    </>
  );
};

export default Actions;
