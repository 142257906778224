import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "./Main";
import Learn from "./Learn";
import Trend from "./Trend";
import Popular from "../../components/Popular";
import Download from "./Download";
import News from "../../components/News";
import Steps from "./Steps";
import requestHandler from "../../actions/httpClient";
import { userStatusCreator } from "../../actions/getUserStatus";
import { useNavigate } from "react-router";
import { socket } from "../../socket";
import { socketDataReceived } from "../../actions/markets/index";

const Home = ({ checkSignature }) => {
  const scrollToRef = useRef(null);
  const dispatch = useDispatch();
  const [marketsData, setMarketsData] = useState([]);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const { socketMarketData } = useSelector((state) => state.markets);

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      navigate(1);
    };
    if (localStorage.getItem('signature') && localStorage.getItem('signature') !== null
      && localStorage.getItem('jwt_token') && localStorage.getItem('jwt_token') !== null
      && localStorage.getItem('switch_exchange') && localStorage.getItem('switch_exchange') !== null) {
      // getRegion();
      dispatch(userStatusCreator());
    }
  }, [checkSignature]);

  useEffect(() => {
    socket.connect(() => { });
    socket.connect();
    socket.on("international_markets_web", (markets) => {
      dispatch(socketDataReceived(markets));
    });

    return () => {
      socket.off("international_markets_web");
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <Main
        scrollToRef={scrollToRef}
        loading={socketMarketData?.length <= 0 && loading}
        socketMarketData={socketMarketData}
      />
      <Trend
        socketMarketData={socketMarketData}
        loading={socketMarketData?.length <= 0 && loading}
      />
      <Learn scrollToRef={scrollToRef} />
      <Popular classSection="section-bg section-mb0" />
      <Download />
      <News classSection="section-bg" />
      <Steps />
    </>
  );
};

export default Home;
